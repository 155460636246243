import * as React from "react"
import { Breadcrumb, Container, Row, Col } from "react-bootstrap"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import { Link, navigate } from "gatsby"
import axios from "axios"

// the below async function wait till get data from strapi using graphql
const getPropurl = async (handler, crmid) => {
  let result = []
  //instead of hit REST we use json format to get only required fields. we dont import apollo since this call from another Hook
  var data = JSON.stringify({
    "query": "query($crm_id:[String]) { properties(where: { listing_id: $crm_id}) { id, slug, search_type, department, ghost_property } }",
    "variables": { "crm_id": [crmid] }
  });
  console.log("🚀 ~ file: 404.js ~ line 17 ~ getPropurl ~ data", data)
  var config = {
    method: "post",
    url: `${process.env.GATSBY_STRAPI_SRC}/graphql`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
    data: data,
  }
  const res = await axios(config)
  result[0] = JSON.stringify(res.data)
  handler(result) //update state var
}

const ErrorPage = props => {
  const [showtemplate, setShowtemplate] = React.useState(false)
  const [propdata, setPropdata] = React.useState("") // false
  const redirect = (pathname)=> {
    if(pathname.indexOf("/property-for-sale/") >= 0){
      navigate("/property/for-sale/in-dubai/") 
    }else if(pathname.indexOf("/property-for-rent/") >= 0){
      navigate("/property/for-rent/in-dubai/") 
    }else if(pathname.indexOf("/commercial-property-for-sale/") >= 0){
      navigate("/commercial-property/for-sale/in-dubai/") 
    }else if(pathname.indexOf("/commercial-property-for-rent/") >= 0){
      navigate("/commercial-property/for-rent/in-dubai/") 
    }else{
      setShowtemplate(true) 
    } 
  }
  React.useEffect(() => {
    //we doing here as fallback, if any case build failed or not triggered then the below will be useful; when build happens redirects will happen a t netlify itself, so dont need to do CSR
    let regex = ""; //you can modify the preg as per proj feed
    let pathname = props?.location?.pathname;
    if (pathname) {
      let crm_id="";
			if(pathname?.length == 11){
				regex = "\[a-zA-Z]{3}-[a-zA-Z]{1}-[0-9]{4}";  //you can modify the preg as per proj feed
			} else if(pathname?.length == 12){
				regex = "\[a-zA-Z]{3}-[a-zA-Z]{1}-[0-9]{5}";  //you can modify the preg as per proj feed
			}
			crm_id = (pathname).match(regex);
      //mke sure state value is empty and we have proper url to proceed
      if (typeof crm_id != "undefined" && crm_id != null && crm_id.length > 0 && !propdata) {
        getPropurl(setPropdata, crm_id[0]) // call the function to fetch data from strapi
      } else if (propdata) {
        let property_data = JSON.parse(propdata)
        let property_arr = property_data.data.properties
        if (property_arr.length > 0) {
          let property = property_arr[0]
          let uriStr = (property.department == "commercial") ? 'commercial-': '';
          //set the redirection logic, same mentioned in the gatsby-node for _redirects.txt ie., crm_id to details page
          //if (property.department == "residential" || property.department == "commercial") {

            if (property.id) {
              if (property.search_type == "sales" && !property.ghost_property) {
                navigate(`/${uriStr}property-for-sale/${property.slug}-${property.id}`, {
                  replace: true,
                })
              } else if (property.search_type == "lettings" && !property.ghost_property) {
                navigate(`/${uriStr}property-for-rent/${property.slug}-${property.id}`, {
                  replace: true,
                })
              } else if (property.ghost_property) {
                navigate(`/property-details/${property.slug}-${property.id}`, {
                  replace: true,
                })
              }
            }
          //}
        } else {
           redirect(pathname) 
        }
      } else {
        redirect(pathname) // show 404 page template
      }
    }
  }, [propdata])

  return (
    <>
      {showtemplate && (
        <div className="contact-page">
          <Header alt />
          <section className="errorpage contact-book">
            <Container>
              <Row className="d-flex justify-content-center bd-highlight mb-3 text-center">
                <Col md="9">
                  <h1>
                    We're sorry, the page you were looking for cannot be found.
                  </h1>
                  <p>
                    This is maybe because it has been removed, we've changed its
                    name or it is temporarily unavailable.
                  </p>
                  <div className="banner-ctas">
                    <Link to="/property/for-sale/in-dubai/" className="btn">
                      Find a Property <i class="icon-right"></i>
                    </Link>
                    <Link to="/contact/" className="btn btn-secondary">
                      Contact Us<i class="icon-right"></i>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <Footer />
        </div>
      )}
    </>
  )
}
export default ErrorPage
